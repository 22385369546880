import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi2";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

import { useSearchParams } from "react-router-dom";

const DataTable = React.memo(
  ({
    columns,
    data,
    header,
    classNameHeader,
    footer,
    backgroundColor,
    selectable,
    onChange,
    pagination,
    lite,
    emptyText,
    headClassName = "",
    paramsValue,
    type = false,
    renderItem = false,
  }) => {
    // const [selected, setSelected] = useState([]);
    // const selected = useRef([]);
    const [searchParams, setSearchParams] = useSearchParams(paramsValue);

    // const isSelected = (index) =>
    //   !!selected.current.find((e) => e.index === index);

    // const onSelected = useCallback(
    //   ({ index, item }) => {
    //     if (isSelected(index)) {
    //       let newSelected = selected.current.filter((e) => e.index != index);
    //       selected.current = newSelected;
    //       onChange && onChange(newSelected);
    //     } else {
    //       let newSelected = [...selected.current, { index, item }];
    //       selected.current = newSelected;
    //       onChange && onChange(newSelected);
    //     }
    //   },
    //   [selected.current]
    // );

    // const onSelectedAll = useCallback(() => {
    //   if (data.length === selected.current.length) {
    //     selected.current = [];
    //     onChange && onChange([]);
    //   } else {
    //     let newSelected = data.map((item, index) => ({
    //       index,
    //       item,
    //     }));
    //     selected.current = newSelected;
    //     onChange && onChange(newSelected);
    //   }
    // }, [selected.current, data]);

    // useEffect(() => {
    //   selected.current = [];
    // }, [data]);

    const footerView = useMemo(() => {
      return footer ? (
        <div className="p-4">{footer}</div>
      ) : (
        pagination && (
          <div className="px-2 pt-3 fs-08 d-flex justify-content-end align-items-center sticky pagination">
            <span className="me-4">
              Страница {pagination.currentPage} из {pagination.totalPages}
            </span>
            <button
              className="me-2"
              onClick={() => setSearchParams(searchParams.delete("page"))}
            >
              <HiChevronDoubleLeft
                size={16}
                className={
                  Number(pagination.currentPage) <= 1
                    ? "text-muted"
                    : "text-dark"
                }
              />
            </button>
            <button
              className="me-4"
              onClick={() => {
                if (Number(pagination.currentPage) >= 3) {
                  searchParams.set("page", Number(pagination.currentPage) - 1);
                } else {
                  searchParams.delete("page");
                }
                setSearchParams(searchParams);
              }}
            >
              <HiChevronLeft
                size={16}
                className={
                  Number(pagination.currentPage) <= 1
                    ? "text-muted"
                    : "text-dark"
                }
              />
            </button>
            <button
              className="me-2"
              onClick={() => {
                if (
                  Number(pagination.currentPage) < Number(pagination.totalPages)
                ) {
                  searchParams.set("page", Number(pagination.currentPage) + 1);
                  setSearchParams(searchParams);
                }
              }}
            >
              <HiChevronRight
                size={16}
                className={
                  Number(pagination.currentPage) >=
                  Number(pagination.totalPages)
                    ? "text-muted"
                    : "text-dark"
                }
              />
            </button>
            <button
              onClick={() => {
                if (
                  Number(pagination.currentPage) < Number(pagination.totalPages)
                ) {
                  searchParams.set("page", pagination.totalPages);
                  setSearchParams(searchParams);
                }
              }}
            >
              <HiChevronDoubleRight
                size={16}
                className={
                  Number(pagination.currentPage) >=
                  Number(pagination.totalPages)
                    ? "text-muted"
                    : "text-dark"
                }
              />
            </button>
          </div>
        )
      );
    }, [pagination]);

    if (type == "custom" && renderItem) {
      const body = useMemo(() => {
        if (!data || data?.length === 0) {
          return (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <svg
                className="mb-3"
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.32"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                  fill="#999"
                />
                <path
                  d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
                  fill="#999"
                />
              </svg>
              <h5>{emptyText ?? "Ничего не найдено"}</h5>
            </div>
          );
        }
        return data && data.map((item, index) => renderItem(item, index));
      }, [data]);

      return (
        <>
          {body}
          {footerView}
        </>
      );
    }
    const head = useMemo(() => {
      return (
        <thead className={"head gx-0 " + headClassName}>
          {/* {selectable && (
            <Col className="col-auto">
              <Form.Check
                aria-label="all"
                onChange={() => onSelectedAll()}
                checked={
                  data?.length > 0
                    ? data.length === selected.current.length
                    : false
                }
              />
            </Col>
          )} */}
          <tr>
            {columns.map(({ name = "", size, width, align }, index) => {
              let optionAlign =
                align == "right"
                  ? "d-flex text-right justify-content-end align-items-center"
                  : align == "center"
                  ? "d-flex text-center justify-content-center align-items-center"
                  : "d-flex text-left justify-content-start align-items-center";
              return (
                <td
                  key={index}
                  style={width && { maxWidth: width }}
                  md={size}
                  className={[
                    "px-1",
                    size && "col",
                    size == "auto" && "col-auto",
                    optionAlign,
                  ]}
                >
                  {name}
                </td>
              );
            })}
          </tr>
        </thead>
      );
    }, [data]);

    const headerView = useMemo(() => {
      return header && <div className={classNameHeader}>{header}</div>;
    }, [classNameHeader]);

    const body = useMemo(() => {
      const List = memo(({ item }, index) => {
        index++;
        const [open, setOpen] = useState({ show: false, data: false });
        return (
          <tr key={index}>
            {columns.map(
              (
                {
                  selector = "",
                  cell,
                  align,
                  size,
                  width,
                  showDesc,
                  showDescIcon = true,
                },
                index
              ) => {
                let optionAlign =
                  align == "right"
                    ? "d-flex text-right justify-content-end align-items-center"
                    : align == "center"
                    ? "d-flex text-center justify-content-center align-items-center"
                    : "d-flex text-left justify-content-start align-items-center";
                return (
                  <td
                    key={index}
                    align={align}
                    md={size}
                    style={width && { maxWidth: width }}
                    className={[
                      "px-1",
                      size ? "col" : "",
                      size == "auto" ? "col-auto" : "",
                      optionAlign,
                    ]}
                  >
                    {showDesc ? (
                      <a
                        onClick={() =>
                          setOpen({ show: !open.show, data: desc(item) })
                        }
                      >
                        <span>{cell ? cell(item, index) : item[selector]}</span>
                        {showDescIcon && (
                          <span className="ms-2">
                            {open.show ? (
                              <IoChevronUpOutline size={15} />
                            ) : (
                              <IoChevronDownOutline size={15} />
                            )}
                          </span>
                        )}
                      </a>
                    ) : cell ? (
                      cell(item, index)
                    ) : (
                      item[selector]
                    )}
                  </td>
                );
              }
            )}
            {open.show && open.data}
          </tr>
        );
      });
      if (!data || data?.length === 0) {
        return (
          <tbody>
            <tr>
              <td colspan="20">
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  <svg
                    className="mb-3"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.32"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                      fill="#999"
                    />
                    <path
                      d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
                      fill="#999"
                    />
                  </svg>
                  <h5>{emptyText ?? "Ничего не найдено"}</h5>
                </div>
              </td>
            </tr>
          </tbody>
        );
      }
      return (
        <tbody>
          {data &&
            data.map((item, index) => (
              <List key={index} item={item} index={index} />
            ))}
        </tbody>
      );
    }, [data]);

    return (
      // <div className={"custom-table" + (lite ? " lite" : "")}>
      //   {headerView}
      //   <div>
      //     {columns && (
      //       <>
      //         {head}
      //         {body}
      //       </>
      //     )}
      //   </div>
      //   {footerView}
      // </div>
      <>
        {headerView}
        <div class="table-responsive">
          <table className="tableResponsive">
            {columns && (
              <>
                {head}
                {body}
              </>
            )}
          </table>
        </div>
        {footerView}
      </>
    );
  }
);
export default DataTable;
