const BASE_URL = process.env.REACT_APP_BASE_URL;
const FILE_URL = process.env.REACT_APP_BASE_URL + "/file";

const apiRoutes = {
  // Auth
  AUTH_LOGIN: BASE_URL + "/auth/login",
  AUTH_CHECK: BASE_URL + "/auth/check",
  AUTH_REFRESH: BASE_URL + "/auth/refresh",
  AUTH_LOGOUT: BASE_URL + "/auth/logout",

  // Order
  ORDERS: BASE_URL + "/order",
  ORDER: BASE_URL + "/order/one",

  // Task
  TASKS: BASE_URL + "/task",
  TASK: BASE_URL + "/task/one",

  // Message
  MESSAGES: BASE_URL + "/message",
  MESSAGES_GENERAL: BASE_URL + "/message/general",
  MESSAGE: BASE_URL + "/message/one",
  DIALOGS: BASE_URL + "/message/dialogs",
  MESSAGES_REPORT: BASE_URL + "/message/report",

  // Category
  CATEGORIES: BASE_URL + "/category",
  CATEGORY: BASE_URL + "/category/one",
  CATEGORY_CREATE: BASE_URL + "/category/create",
  CATEGORY_EDIT: BASE_URL + "/category/edit",

  // Member
  MEMBERS_GET: BASE_URL + "/member",
  MEMBER_GET: BASE_URL + "/member/one",
  MEMBER_CREATE: BASE_URL + "/member/create",

  // Стоп-слова
  STOPWORDS: BASE_URL + "/stopword",
  STOPWORD: BASE_URL + "/stopword/one",

  // Product
  PRODUCTS: BASE_URL + "/product",
  PRODUCT: BASE_URL + "/product/one",
  PRODUCT_CREATE: BASE_URL + "/product/create",

  // Document
  DOCUMENTS: BASE_URL + "/document",
  DOCUMENT: BASE_URL + "/document/one",
  DOCUMENT_EDIT: BASE_URL + "/document/edit",

  // Транзакции
  TRANSACTIONS: BASE_URL + "/transaction",
  TRANSACTION: BASE_URL + "/transaction/one",

  // Statistic
  STATISTIC: BASE_URL + "/statistic",

  // User
  USER: BASE_URL + "/user",
  USER_ONE: BASE_URL + "/user/one",
  USER_CREATE: BASE_URL + "/user/create",
  USER_EDIT: BASE_URL + "/user/edit",
  USER_BLOCKED: BASE_URL + "/user/blocked",

  SESSIONS: BASE_URL + "/user/sessions",
  HISTORIES: BASE_URL + "/history",
  HISTORY: BASE_URL + "/history/one",

  // Options
  OPTIONS: BASE_URL + "/option/",

  // Параметры
  PARAM: BASE_URL + '/category/param',
  PARAM_STATUS: BASE_URL + '/category/param/status',

  // Опции
  OPTION: BASE_URL + '/category/option',
  OPTION_STATUS: BASE_URL + '/category/option/status'
};

const apiRejectMessages = {
  DEFAULT: "Что-то пошло не так, повторите попытку позже",
  INVALID_KEY: "Неверный ключ подтверждения",
  USER_EXISTS: "Пользователь с таким номером уже существует",
  USER_NOT_FOUND: "Такого пользователя не существует",
  USER_NOT_EXIST: "Такого пользователя не существует",
  CART_NOT_VALID_COUNT: "Значение не может быть меньше 1",
  PAGE_ERROR:
    "Не удалось загрузить страницу, вернитесь назад или перезагрузите страницу",
};

export {
  BASE_URL,
  FILE_URL,
  apiRoutes,
  apiRejectMessages,
};