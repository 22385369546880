import moment from "moment";
import React, { useState } from "react";
import {
  IoCheckmarkDoneOutline,
  IoCheckmarkOutline,
  IoBanSharp,
} from "react-icons/io5";
import { reportMessage } from "../../services/message";

const Message = ({
  id,
  my,
  time,
  text,
  name,
  view = false,
  status = true,
  admin = false,
}) => {
  time = time
    ? moment(time).format("DD MMMM YYYY kk:mm")
    : moment().format("DD MMMM YYYY kk:mm");
  const [isBanned, setIsBanned] = useState(status);

  const onBan = () => {
    reportMessage({ id }).then((res) => setIsBanned(false));
  };

  return (
    <div className={my ? "message my" : "message"}>
      {admin == false && (
        <div className="message-avatar">
          <img src="/images/avatar.png" alt={name} />
        </div>
      )}
      <div className="message-main">
        <div className="message-main-top">
          {/* {
            (admin) &&
            <span className="orange ms-3">Сообщение от админитрации</span>
          } */}
          <time>{time}</time>
          {admin == false && !my && (
            <button
              type="button"
              className={!isBanned ? "ban banned" : "ban"}
              onClick={() => onBan()}
            >
              <IoBanSharp />
            </button>
          )}
        </div>
        <div
          className={
            !isBanned ? "message-main-text banned" : "message-main-text"
          }
        >
          {!admin && name && <p className="fw-6">{name}</p>}
          <p>{!isBanned ? "Сообщение заблокировано администрацией" : text}</p>
          {!isBanned ? null : view ? (
            <IoCheckmarkDoneOutline className="viewed" />
          ) : (
            <IoCheckmarkOutline />
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
